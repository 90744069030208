import { useState } from "react"
import QrReader2 from "react-qr-scanner"

import "./App.css"

function App() {
	const [courseId, setCourseId] = useState("")
	const [classId, setClassId] = useState("")
	const [userId, setUserId] = useState("")
	const [attendanceStatus, setAttendanceStatus] = useState("Scan the QR code")
	const [viewScanner, setViewScanner] = useState(true)

	const handleScan = (data) => {
		if (data) {
			console.log(data.text)
			const pattern = /^{"courseId":.*?,"classId":.*?,"userId":.*?}$/
			if (pattern.test(data.text)) {
				setAttendanceStatus("Scanned QR. Processing ...")
				extractData(data.text)
				setViewScanner(false)
				giveAttendance(data.text)
			} else {
				setClassId("")
				setCourseId("")
				setUserId("")
				setAttendanceStatus("Not a valid ELABS Attendance QR")
				//setViewScanner(true)
			}
		}
	}
	const extractData = (data) => {
		const scannedObj = JSON.parse(data)
		if (scannedObj) {
			setClassId(scannedObj.classId)
			setCourseId(scannedObj.courseId)
			setUserId(scannedObj.userId)
		}
	}

	const giveAttendance = async (data) => {
		const scannedObj = JSON.parse(data)

		const body = {
			attendance: [
				{
					classId: scannedObj.classId,
					studentId: scannedObj.userId,
				},
			],
		}
		const result = await fetch(
			process.env.REACT_APP_SERVER_URL + "/attendance",
			{
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify(body),
			}
		)

		const resp = await result.json()
		console.log(resp)

		if (resp.data[0].isAttendanceGiven) {
			setAttendanceStatus("Attendance Given")
			setViewScanner(true)
		} else {
			setAttendanceStatus(resp.data[0].error)
			setViewScanner(true)
		}
	}

	const handleError = (err) => {
		console.log(err)
	}

	const previewStyle = {
		width: 300,
	}

	return (
		<div className='App'>
			<h2>ELABS ATTENDANCE</h2>
			{viewScanner && (
				<QrReader2
					delay={100}
					facingMode={"rear"}
					style={previewStyle}
					onError={handleError}
					onScan={handleScan}
				/>
			)}
			{classId !== "" && (
				<div>
					<strong>CLASS ID : </strong>
					{classId}
				</div>
			)}
			{userId !== "" && (
				<div>
					<strong>USER ID : </strong>
					{userId}
				</div>
			)}
			{courseId !== "" && (
				<div>
					<strong>COURSE ID : </strong>
					{courseId}
				</div>
			)}

			{attendanceStatus !== "" && (
				<div>
					<strong>STATUS : </strong>
					{attendanceStatus === "Attendance Given" ? (
						<span style={{ color: "green" }}>{attendanceStatus}</span>
					) : (
						<span style={{ color: "red" }}>{attendanceStatus}</span>
					)}
				</div>
			)}

			{!viewScanner && (
				<button
					onClick={() => {
						setViewScanner(true)
					}}
				>
					Open scanner
				</button>
			)}
		</div>
	)
}

export default App
